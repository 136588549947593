import React, { useContext, useEffect } from "react"
import styled from "styled-components";
import Container from "../components/common/Container";
import Layout from "../templates/layout"
import { HeaderContext } from "../context/HeaderContext";

const Error = styled.div`
  padding: 200px 0;
  text-align: center;
`;

const NotFound = props => {
  const { setTheme, setPageTheme } = useContext(
    HeaderContext
  );

  useEffect(() => {
    setTheme('dark');
    setPageTheme('dark');
  }, []);

  return (
    <Layout>
      <Error>
        <Container>
            <h1>404 - Page not found</h1>
        </Container>
      </Error>
    </Layout>
  );
}

export default NotFound
